@import 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';

[class*="fontawesome-"]:before {
    font-family: 'fontawesome', sans-serif;
}


.select-search-box {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 4px;
}

.select-search-box__options{
  margin: 0px;
  padding: 0px;
}

    .select-search-box--multiple {
        border-radius: 4px;
        box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
        overflow: hidden;
    }

    .select-search-box::after {
        font-family: fontawesome;
        content:"\f078";
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        text-align: center;
        line-height: 50px;
        color: #222f3e;
        z-index: 1;
    }

    .select-search-box--multiple::after {
        content:"\f002";
    }

    .select-search-box--input::after {
        display: none !important;
    }

    .select-search-box__out {
        display: none;
    }

    .select-search-box__search {
        display: block;
        width: 100%;
        height: 40px;
        border-radius: 4px;
        border: none;
        background: none;
        outline: none;
        font-size: 15px;
        padding: 6px 10px;
        background-color: #fff;
        color: #333;
        -webkit-appearance: none;
        box-sizing: border-box;
        position: relative;
        z-index: 2;
        cursor: pointer;
        box-shadow: 0 0 0 1px #d9d9d9;
    }

    input.select-search-box__search {
        line-height: 1;
    }

    .select-search-box--multiple .select-search-box__search {
        box-shadow: none;
    }

    .select-search-box--input .select-search-box__search {
        cursor: text;
    }

        .select-search-box__search:focus {
            cursor: text;
        }
        .select-search-box--focus .select-search-box__search {
          border-radius: 4px 4px 0px 0px;
          cursor: text;
      }

        .select-search-box__search--placeholder {
            font-style: italic;
            font-weight: normal;
        }

    .select-search-box input::-webkit-input-placeholder {
        color: #ccc;
        font-style: italic;
        font-weight: normal;
    }

    .select-search-box input::-moz-placeholder {
        color: #ccc;
        font-style: italic;
        font-weight: normal;
    }

    .select-search-box input:-moz-placeholder {
        color: #ccc;
        font-style: italic;
        font-weight: normal;
    }

    .select-search-box input:-ms-input-placeholder {
        color: #ccc;
        font-style: italic;
        font-weight: normal;
    }

    input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    .select-search-box__select {
        display: none;
        position: absolute;
        top: 41px;
        left: 0;
        right: 0;
        background: #fff;
        border-radius: 0px 0px 4px 4px;
        overflow: auto;
        box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
        z-index: 100;
        min-height: 49px;
    }

    .select-search-box--multiple .select-search-box__select {
        display: block;
        position: static;
        border-top: 1px solid #eee;
        border-radius: 0;
        box-shadow: none;
    }

        .select-search-box__select--display {
            display: block;
        }

    .select-search-box__option {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 400;
        color: #333;
        padding: 7px;;
        border-top: 1px solid #eee;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: none;
        height: 30px;
    }

        .select-search-box__option:first-child {
            border-top: none;
        }

        .select-search-box__option--hover, .select-search-box__option:hover {
            background: #f4f7fa;
        }

        .select-search-box__option--selected {
            background: #54A0FF;
            color: #fff;
            border-top-color: #2184ff;
        }

            .select-search-box__option--selected:hover, .select-search-box__option--selected.select-search-box__option--hover {
                background: #2184ff;
                color: #fff;
                border-top-color: #2184ff;
            }

        .select-search-box__group {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid #eee;
            position: relative;
        }

        .select-search-box__group-header {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            padding: 0 10px;
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
        }
