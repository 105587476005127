#root {
  --main-color-dark: rgb(15, 23, 42);
  --main-color-light: #4fc4f9;
  margin-bottom: 30px;
  height: 100%;
}
.placeholder {
  border: 5px solid black;
  padding: 10px;
  margin-top: 10px;
}
.stuck-top {
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
}
body,
html {
  height: 100%;
  max-width: 100% !important;
  overflow-x: hidden !important;
}
.wrapper {
  background-color: #f9f9fa;
  padding: 30px;
  /* max-height: 100%;
  */
  width: 55%;
}
h4.text-header {
  margin: 0 0 5px 0;
  font-weight: 500;
  color: #0d8bd1;
}
input:invalid {
  background-color: white;
}
.custom-checkbox {
  position: absolute;
  top: auto;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE 6/7 */
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  white-space: nowrap;
}
.custom-checkbox + label:before {
  content: "";
  background: #fff;
  border: 4px solid #d6dcde;
  background-color: rgba(255, 255, 255, 0.8);
  display: block;
  box-sizing: border-box;
  float: left;
  width: 20px;
  height: 20px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 0.08s ease-out;
}
.custom-checkbox:checked + label:before {
  border: 4px solid #0d8bd1;
  background-color: #0d8bd1;
  box-shadow: inset 0 0 0 0.22em rgba(255, 255, 255, 0.95);
}
input[type="radio"].custom-checkbox + label:before {
  border-radius: 100%;
}
input[type="checkbox"].custom-checkbox + label:before {
  content: "\002713";
  font-weight: 700;
  text-align: center;
  line-height: 1.15;
  color: rgba(0, 0, 255, 0);
  background-color: #d6dcde;
  border-radius: 5px;
}
input[type="checkbox"].custom-checkbox:checked + label:before {
  background-color: #0d8bd1;
  color: white;
  font-size: 16px;
  line-height: 0.75;
  box-shadow: none;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.thank-you-message {
  font-size: 22px;
  max-width: 600px;
  text-align: center;
  margin-bottom: 40px;
}
@media (max-width: 700px) {
  .thank-you-message {
    width: 100%;
  }
}
.thank-you-copy {
  text-align: center;
  width: 100%;
  max-width: 550px;
  margin-bottom: 10px;
}
@media (max-width: 700px) {
  .thank-you-copy {
    width: 100%;
  }
}
.thank-you-copy h1 {
  font-size: 25px;
}
.thank-you-copy h3 {
  font-size: 18px;
}
.thank-you-copy .subtext {
  font-size: 13px;
}
.thank-you-page {
  color: #565656;
  background: #fff;
  border: 1px solid #b8b8b8;
  box-sizing: border-box;
  box-shadow: 2px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  padding: 60px;
  margin-bottom: 40px;
  max-width: 600px;
  width: 100%;
}
@media (max-width: 700px) {
  .thank-you-page {
    width: 100%;
    border: none;
    padding: 30px;
    box-shadow: none;
  }
}
.thank-you-page .thank-you-heading {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.thank-you-page .thank-you-section.bond,
.thank-you-page .thank-you-section.owners {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.thank-you-page .thank-you-section.bond > *,
.thank-you-page .thank-you-section.owners > * {
  width: 50%;
  margin-bottom: 10px;
}
.thank-you-page .thank-you-section.bond {
  padding-bottom: 30px;
}
.thank-you-page .value {
  text-transform: capitalize;
}
.thank-you-page .thank-you-middle {
  border-top: 1px solid #a5a5a5;
  border-bottom: 1px solid #a5a5a5;
  padding: 30px 0px;
  margin-bottom: 30px;
}
.thank-you-page .tb {
  display: flex;
  flex-direction: column;
}
.thank-you-page .lr {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.thank-you-page .lr > * {
  width: 50%;
}
.thank-you-page .black-label {
  font-weight: 600;
  font-size: 16px;
}
@media (max-width: 600px) {
  .main-container {
    margin: 0px;
  }
}
.trust-wrapper {
  position: absolute;
}
.checkout-title {
  margin-bottom: 13px;
}
.anchor {
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  border-bottom: 1px solid #444;
  cursor: pointer;
}
.progress-bar-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 15px;
}
.is-verify .main-page {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  transition: all 0.2s ease-in;
}
.progress-bar {
  z-index: 0;
  transition: all 0.3s ease-out;
  width: 60%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
@media (max-width: 800px) {
  .progress-bar {
    transition: all 0.3s ease-out;
    margin-top: 40px;
    width: 74%;
  }
  .progress-bar .step .step-icon {
    font-size: 10px;
    height: 15px !important;
    width: 15px !important;
  }
}
.progress-bar .step {
  transition: all 0.2s ease-in;
}
.progress-bar .step .step-icon {
  z-index: 10;
  color: white;
}
.progress-bar .step.active .step-icon {
  transform: scale(1.2);
  transition: all 0.2s ease-in;
  transition-delay: 0.3s;
  z-index: 2;
}
.progress-bar .step.active .step-name {
  transition-delay: 0.9s;
  font-weight: 500;
  transform: scale(1.06);
  transition: all 0.3s ease-in;
}
.progress-bar .step-name {
  transition: top 0.2s ease-in;
  transition-delay: 0.9s;
  transform: scale(1);
  font-size: 15px;
  font-weight: 400;
  color: #156c9f;
  top: -25px;
  min-width: 33%;
  text-align: center;
  position: absolute;
}
@media (max-width: 500px) {
  .progress-bar .step-name {
    font-size: 12px;
    min-width: 33%;
    text-align: center;
  }
}
.progress-bar .step-line {
  position: relative;
  height: 3px;
  transition: all 0.3s ease-in;
  flex-grow: 1;
  background: #156c9f 100%;
  transition: all 0.5s ease-in;
}
.progress-bar .step-line-progress-bar {
  position: absolute;
  content: "";
  height: 3px;
  background: #99c432;
}
.progress-bar .step {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.progress-bar .step .step-icon {
  transition: all 0.2s ease-in;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px solid #156c9f;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-bar .step .step-icon.fa-check {
  border: 3px solid #99c432;
  color: white;
  background-color: #99c432;
}
.basic-details {
  margin-bottom: 10px;
  background-color: #156c9f;
  color: white;
}
.basic-details .bond-amount {
  font-weight: 400;
  margin-bottom: -5px;
  color: white;
}
.basic-details .bond-state {
  font-size: 25px;
  font-weight: 600;
}
.basic-details .bond-name {
  font-size: 15px;
  line-height: 10px;
  color: white;
}
.verify-yes .accordian {
  display: none;
}
.verify-yes .verify-mobile {
  display: block;
}
.verify-no .accordian {
  display: block;
}
.verify-no .verify-mobile {
  display: none;
}
@media (max-width: 600px) {
  .verify-mobile {
    padding: 10px !important;
  }
}
.accordian-item {
  box-shadow: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  padding: 30px;
  margin-bottom: 5px;
  background-color: #f9f9fa;
}
.accordian-item .panel-edit-btn {
  font-size: 20px;
  text-transform: capitalize;
}
.accordian-item.active {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.accordian-body.active {
  height: auto;
  display: block;
  transform: all 0.5s ease-in-out;
}
.accordian-body.inactive {
  height: 0;
  display: none;
  transform: all 0.5s ease-in-out;
}
.panel-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.panel-heading.inactive > *:not(.panel-edit-btn) {
  opacity: 0.25;
}
.panel-type {
  display: flex;
  align-items: center;
}
.panel-type .panel-icon {
  margin-right: 10px;
}
.panel-type .panel-title {
  font-size: 20px;
}
.fade-enter {
  opacity: 0.1;
}
.fade-enter-active {
  opacity: 1;
  transition: all 0.3s;
}
.fade-leave {
  opacity: 1;
}
.fade-leave-active {
  opacity: 0.1;
  transition: all 0.3s;
}
.form-input,
input[type].form-input {
  -webkit-appearance: none;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  word-break: normal;
  font-size: 15px;
  color: #333;
  font-weight: 400;
  height: 40px;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  transition: all 0.2s ease;
  box-shadow: none;
}
.form-input:focus,
input[type].form-input:focus {
  border: 2px solid #0d8bd1;
}
.form-input.has-error,
input[type].form-input.has-error {
  border: 2px solid crimson;
}
.error-text {
  color: crimson;
  font-weight: 600;
  font-size: 17px;
}
.split-layout {
  display: flex;
  flex-direction: row;
}
.split-layout .title-wrap {
  border-radius: 5px;
}
.form-input:focus {
  box-shadow: 0 0 0 2px #0d8bd1;
}
.has-error {
  border-bottom: 1px solid crimson;
}
.side-bar {
  margin-left: 40px;
  width: 45%;
}
.info-box {
  display: flex;
  background-color: var(--main-color-dark);
  color: white;
  flex-direction: row;
  padding: 15px;
  align-items: center;
  margin-bottom: 20px;
}
.info-box__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: white;
  margin-right: 20px;
}
.info-box__icon .fa-user {
  font-size: 25px;
  color: #156c9f;
  margin-top: -2px;
}
.info-bar__text {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.info-bar__text .text-header {
  font-size: 20px;
  font-weight: 600;
}
.info-bar__text .phone,
.info-bar__text .email {
  display: block;
  color: white;
  text-decoration: none;
}
.panels {
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 1s ease-in-out;
}
.panels .panel.disabled {
  transition: all 1s ease-in-out;
  position: absolute;
  opacity: 0;
}
input + label.toggle-label {
  background-color: lightgray;
  color: white;
  padding: 7px;
  width: 40px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  font-weight: 500;
  font-size: 13px;
  border-radius: 4px;
}
input + label.toggle-label:not(.price-box) {
  margin-right: 10px;
  width: 40px;
}
input + label.toggle-label.price-box {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  color: black;
  margin-bottom: 10px;
  height: 30px;
  justify-content: space-between;
  position: relative;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  overflow: hidden;
}
input + label.toggle-label.price-box .price-box__label {
  margin-left: 30px;
  position: absolute;
  font-size: 16px;
}
input + label.toggle-label.price-box .price-box__details {
  transform: translateY(0%);
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}
input + label.toggle-label.price-box .price-box__premium {
  padding: 5px;
  transform: translateY(105%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px 5px 5px 0px;
  width: 65px;
  height: 30px;
  right: 0;
  position: absolute;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}
input + label.toggle-label.price-box .price-box__premium small {
  margin-bottom: -5px;
  transform: translateY(0px);
  transition: all 0.1s ease-in-out;
}
input + label.toggle-label.price-box .price-box__premium .amount {
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  color: #0d8bd1;
  transform: translateY(0px);
}
input + label.toggle-label.price-box.all-uw {
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}
input + label.toggle-label.price-box.all-uw .price-box__premium {
  display: none;
}
input + label.toggle-label.price-box.all-ip .price-box__details {
  transform: translate(-80px, 0px);
}
input + label.toggle-label.price-box.all-ip .price-box__details .cart-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
}
input + label.toggle-label.price-box.all-ip .price-box__premium {
  transform: translate(0%, 0%);
  transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0.3s;
  background-color: #0d8bd1;
  height: 100%;
  padding: 2px;
}
input + label.toggle-label.price-box.all-ip .price-box__premium .amount.ip {
  color: white;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
input + label.toggle-label.price-box.all-ip:hover {
  box-shadow: 0 0.125rem 0rem #0d8bd1;
}
input + label.toggle-label.price-box.all-ip:hover .price-box__details {
  transform: translate(-80px, 0px);
}
input + label.toggle-label.price-box.all-ip:hover .price-box__premium {
  transform: translate(0%, 0%);
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0.3s;
}
input
  + label.toggle-label.price-box.all-ip:hover
  .price-box__premium
  .amount.ip {
  font-size: 15px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-delay: 0.1s;
}
input + label.toggle-label:hover.price-box {
  width: 100%;
  background-color: white;
  color: black;
  box-shadow: 0 0.125rem 0rem #156c9f;
  transform: scale(1.01);
}
input + label.toggle-label:hover.price-box .price-box__details {
  transform: translateY(-205%);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
input + label.toggle-label:hover.price-box .price-box__premium {
  transform: translateY(0%);
  transition: all 0.2s ease-out;
  transition-delay: 0.2s;
}
input + label.toggle-label:hover.price-box .price-box__premium small {
  margin-bottom: -5px;
  transform: translateY(-25px);
  transition: all 0.2s ease-out;
  transition-delay: 0.2s;
}
input + label.toggle-label:hover.price-box .price-box__premium .amount.ip {
  font-size: 15px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-delay: 0.4s;
}
input + label.toggle-label:hover.price-box.all-uw {
  transition: all 0.1s ease-in-out;
  box-shadow: 0 0.125rem 0rem #156c9f;
  transform: scale(1.01);
}
input:checked + label.toggle-label {
  background-color: orange;
  color: white;
  padding: 7px;
  text-align: center;
  width: 30px;
  font-weight: 600;
  border-radius: 4px;
}
input:checked + label.toggle-label:not(.price-box) {
  margin-right: 10px;
  width: 40px;
}
input:checked + label.toggle-label.price-box {
  width: 100%;
  background-color: white;
  color: black;
}
input:checked + label.toggle-label.price-box .price-box__details {
  transform: translateY(-205%);
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}
input:checked + label.toggle-label.price-box .price-box__premium {
  transform: translateY(0%);
  transition: all 0.3s ease-in;
  transition-delay: 0.2s;
}
input:checked + label.toggle-label.price-box .price-box__premium small {
  margin-bottom: -5px;
  transform: translateY(-25px);
  transition: all 0.2s ease-out;
}
input:checked + label.toggle-label.price-box .price-box__premium .amount.ip {
  font-size: 15px;
  transform: translateY(0px);
  transition: all 0.2s ease-in-out;
  transition-delay: 0.1s;
}
input:checked + label.toggle-label.price-box.all-ip .price-box__details {
  transform: translate(-80px, 0px);
}
input:checked
  + label.toggle-label.price-box.all-ip
  .price-box__details
  .cart-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
}
input:checked + label.toggle-label.price-box.all-ip .price-box__premium {
  transform: translate(0%, 0%);
  transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0.3s;
}
input:checked
  + label.toggle-label.price-box.all-ip
  .price-box__premium
  .amount {
  font-size: 15px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
input:checked + label.toggle-label.price-box.all-ip:hover .price-box__details {
  transform: translate(-80px, 0px);
}
input:checked + label.toggle-label.price-box.all-ip:hover .price-box__premium {
  transform: translate(0%, 0%);
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0.3s;
}
.radio-wrap {
  position: relative;
  display: flex;
  margin-top: 10px;
  align-items: center;
}
input.toggle-radio {
  position: absolute;
  opacity: 0;
}
.radio-error {
  border: 2px solid crimson;
  border-radius: 4px;
  padding: 4px;
  transition: all 0.2s ease-in-out;
  width: 130px;
}
.small-error {
  margin: 0px 5px;
  font-size: 12px;
  font-style: italic;
  color: crimson;
  font-weight: 600;
}
.panel {
  background: #f9f9fa;
  display: flex;
  flex-direction: row;
  padding: 15px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 15px;
  transition: all 0.2s ease-in-out;
}
.panel.active {
  transform: scale(1.03);
  background: #f1f1f1;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.panel.active .panel-icon {
  position: relative;
}
.panel-bottom {
  display: none;
}
.panel-bottom.show {
  display: block;
}
.panel-icon {
  display: flex;
  align-items: center;
}
.panel-content {
  transition: all 0.5s ease-in-out;
  margin-left: 10px;
  margin-top: 0px;
  flex-grow: 1;
  flex-direction: column;
}
.panel-content.no-details {
  transition: all 0.5s ease-in-out;
  margin-top: 4px;
}
.panel-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.panel-title {
  color: #0d8bd1;
  font-size: 18px;
  font-weight: 400;
}
.panel-edit-btn {
  background: none;
  color: #20a6f1;
  font-weight: 600;
  font-size: 14px;
  padding: 0px;
  text-transform: lowercase;
}
.panel-edit-btn:hover {
  background: none;
  color: #20a6f1;
}
.helper-text {
  font-size: 15px;
  margin: 10px 0px;
}
.helper-text .save-text {
  color: #f89406;
}
.panel-detail-item {
  display: block;
  line-height: 15px;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
}
.panel-detail-item .panel-detail-label {
  margin-right: 5px;
  font-weight: 600;
  display: inline-block;
}
.panel-detail-item .panel-detail-value {
  display: inline-block;
}
.fa-times {
  font-size: 16px;
  color: crimson;
  margin-right: 10px;
}
.contactHub .contacts {
  display: flex;
  flex-direction: column;
}
.contactHub .contacts .contact-item {
  position: relative;
  min-height: 90px;
  background-color: white;
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  justify-content: space-between;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}
.contactHub .contacts .contact-item.incomplete {
  border: 5px solid crimson;
}
.contactHub .contacts .contact-item.incomplete:after {
  position: absolute;
  top: 0px;
  left: 0;
  font-weight: 600;
  right: 0;
  font-size: 16px;
  text-align: center;
  content: "Incomplete!";
  background-color: crimson;
  color: white;
}
.contactHub .contacts .contact-item.add-owner {
  cursor: pointer;
}
.contactHub .contacts .buttons button {
  background: white;
  color: black;
  font-weight: 600;
  border: 1px solid #ddd;
  font-size: 15px;
}
@media (max-width: 600px) {
  .contactHub .contacts .buttons button {
    padding: 5px;
  }
}
.contactHub .contacts .buttons button.add-owner {
  border: none;
}
.contactHub .contacts .buttons button.delete {
  color: crimson;
  margin-right: 5px;
}
.contactHub .contacts .contact-name {
  display: flex;
  flex-direction: column;
}
.contactHub .contacts .contact-name .first-name {
  font-size: 15px;
  font-weight: 600;
  color: black;
}
.contactHub .contacts .contact-name .last-name {
  font-size: 18px;
  font-weight: 600;
  color: black;
}
.verify-page {
  font-size: 15px;
  margin-top: 20px;
}
.verify-section:not(.first) {
  margin: 25px 0px;
}
.verify-edit-btn {
  background: none;
  color: #20a6f1;
  font-weight: 600;
  font-size: 18px;
  border-radius: 4px;
  padding: 5px;
  width: 60px;
  text-transform: lowercase;
}
.verify-title {
  color: #156c9f;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.right-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.verify-panel {
  padding: 15px 20px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin: 0px 10px;
}
.verify-panel.is-contact {
  margin-bottom: 10px;
}
.verify-bond {
  display: flex;
  flex-wrap: wrap;
}
.verify-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.verify-value {
  font-size: 14px;
  font-weight: 500;
}
.verify-label {
  font-size: 14px;
  color: #444;
  font-weight: 500;
}
.verify-company,
.verify-contact {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.verify-company .contact-name,
.verify-contact .contact-name {
  width: 50%;
}
.verify-company .contact-details,
.verify-contact .contact-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.verify-address {
  line-height: 19px;
  font-style: italic;
}
.verify-large {
  font-weight: 600;
  font-size: 20px;
}
@media (max-width: 600px) {
  .verify-large {
    font-size: 16px;
  }
}
.verify-sub {
  font-size: 15px;
  margin-top: -8px;
  color: #777;
}
.verify-sub.strong {
  font-weight: 800;
  font-size: 19px;
  margin-top: -10px;
  color: #444;
}
.form-text {
  margin-top: -5px;
  font-weight: 400;
  font-size: 18px;
  margin-left: 10px;
}
.sub-label {
  display: block;
  font-weight: 100;
  font-size: 14px;
  margin-top: 0px;
  font-style: normal;
  margin-left: 0px;
}
.sub-label2 {
  display: block;
  font-weight: 100;
  font-size: 14px;
  margin-top: 0px;
  margin-left: 0px;
  line-height: 1.4;
  color: #777;
}
.bondSelectionSlider {
  position: relative;
}
.bondSelectionSlider button {
  position: absolute;
  top: 0px;
  right: 0;
  font-size: 12px;
  padding: 5px;
  text-transform: none;
}
.bond-selection__single-value {
  width: 100%;
  padding-right: 10px;
}
.bond-selection__single-value .bond-premium {
  border: none !important;
  display: none !important;
}

/* INSANT PURCHASE CSS */
.instant-purchase-panel {
  padding: 30px;
}
.instant-purchase-heading {
  text-align: center;
  color: #0a6ba1;
  margin-bottom: -30px;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 400;
}
@media (max-width: 450px) {
  .instant-purchase-heading {
    font-size: 18px;
  }
}
.instant-purchase-panel-button {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  line-height: 1.2;
}
.is-active-false div * {
  opacity: 0.7;
}
.is-active-false div button {
  opacity: 1;
}
.is-active-false div button:hover {
  background: none;
  text-decoration: underline !important;
  color: #0d8bd1;
}
.no-padding {
  padding: 0 !important;
}
.PT-active {
  background: #0d8bd1 !important;
}
.PT-active::after {
  border-left-color: #0d8bd1 !important;
}
.PT-active::before {
  border-bottom-color: #0d8bd1 !important;
  border-top-color: #0d8bd1 !important;
}
.PT-complete {
  background: #0a6ba1 !important;
}
.PT-complete::after {
  border-left-color: #0a6ba1 !important;
}
.PT-complete::before {
  border-bottom-color: #0a6ba1 !important;
  border-top-color: #0a6ba1 !important;
}

/* TITLE BONDS PORTAL */
.modal-wrapper {
  z-index: 3;
  display: block;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.5s ease-in-out;
}

.modal-content {
  margin: auto;
  background-color: #fff;
  position: relative;
  padding: 0;
  outline: 0;
  max-width: 900px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 0.5s ease-in-out;
}

/* TITLE BONDS MAIN FILE */
.center-title {
  text-align: center;
}
.panel-warning {
  justify-content: center;
}
.panel-warning .warning-icon {
  color: #ffc107;
  font-size: 26px;
}
.panel-warning .panel-title {
  color: #ffc107;
  font-size: 30px;
  font-weight: 600;
}
.start-text {
  margin: 0 0 5px 0;
  font-weight: 500;
  color: #0d8bd1;
}
.hiddenButton {
  margin-top: -80px;
  transition: all 0.3s ease-in;
}
.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-out-left {
  -webkit-animation: slide-out-left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-out-left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* ---------------------------------------------- * Generated by Animista on 2019-2-26 15:50:15 * w: http://animista.net, t: @cssanimista * ---------------------------------------------- */
/** * ---------------------------------------- * animation slide-in-bottom * ---------------------------------------- */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
/* ---------------------------------------------- * Generated by Animista on 2019-2-26 15:48:55 * w: http://animista.net, t: @cssanimista * ---------------------------------------------- */
/** * ---------------------------------------- * animation slide-out-left * ---------------------------------------- */
@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    max-height: 100px;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
    max-height: 1px;
  }
}
@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    max-height: 100px;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
    max-height: 1px;
  }
}
.input-group {
  display: flex;
  overflow: hidden;
  align-items: center;
  position: relative;
  /* ---------------------------------------------- * Generated by Animista on 2019-2-21 10:11:20 * w: http://animista.net, t: @cssanimista * ---------------------------------------------- */
  /** * ---------------------------------------- * animation flip-in-hor-bottom * ---------------------------------------- */
}
.input-group input {
  margin: 3px;
}
.input-group button {
  margin: 0;
  width: 220px;
  font-weight: 500;
}
.input-group .aClass {
  animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.input-group .aClassDelay {
  animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.5s;
}
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate3d(-20rem, 0, 500rem);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

/* PURCHASE PAGE */
body {
  font-size: 14px;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.clear-float {
  clear: both;
}
.blue {
  color: #0d8bd1;
}
.error-message {
  color: crimson;
  padding: 0;
  font-size: 1em;
  background: none;
  box-shadow: none;
}
.has-error {
  border: 2px solid crimson;
}
.summary-total {
  padding-left: 3em;
}
.balance-due {
  font-size: 1.3em;
  font-weight: 400;
}
.total-line {
  float: right;
  width: 50%;
  margin: 7px 0;
}
.shipping-logo {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 1em;
}
.panel-title {
  margin: 0;
  font-weight: 400;
}
.purchase-section {
  margin-top: 20px;
}
@media print {
  body * {
    font-family: Arial, Helvetica, sans-serif;
  }
  .progress-tracker,
  .checkout-title,
  .trust-div,
  .tab-div > h3,
  #btn-print,
  footer,
  .summary-box-wrapper,
  .checkout-summary,
  .checkout-trust-seals,
  .trust-pilot,
  .hide-on-print,
  #screen-logo {
    display: none;
  }
  #print-logo {
    display: block !important;
  }
  div.tab-div > p.tab-content {
    font-size: 10px;
    position: absolute;
    top: 80px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .checkout-form-wrapper {
    margin-top: -50px;
    width: 100%;
    position: absolute;
    bottom: 20px;
  }
  .checkout-form {
    border: 0;
    margin: 0;
    padding: 0;
  }
  .coverhound-wrapper {
    display: none;
  }
  .checkout-form-wrapper {
    position: fixed !important;
    top: 52mm;
  }
  .checkout-form-wrapper .checkout-form .checkout-form-title {
    display: none;
  }
  .checkout-form-wrapper > .checkout-form > .checkout-form-content {
    /*margin-top: -20px;
   */
    width: 90%;
  }
  .ambox-wrapper,
  ambox {
    position: absolute;
    width: 40%;
    bottom: 18mm;
    right: 40px;
    z-index: 10;
    border: 1px solid #aaa;
    border-radius: 10px;
  }
  .ambox-content {
    z-index: 99;
    margin-top: -20px !important;
    margin-left: 20px;
    padding: 10px !important;
  }
  .ambox-wrapper .ambox-content h5 {
    display: none;
  }
  .page-header-left {
    left: 10mm;
    top: 15px;
    position: absolute;
    z-index: 99;
  }
  .page-header-left > a > img {
    height: 40px;
  }
  .page-topbar-right {
    right: 10mm;
    top: 15px;
    position: absolute;
    z-index: 99;
  }
  .page-topbar-right > a {
    text-shadow: none !important;
  }
  .section.container {
    display: inline;
  }
}
